﻿function loadScript(src, options) {
    var s,
        r,
        t;
    r = false;
    s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = src;
    if (options !== null
        && options !== undefined
        && options.integrity !== null
        && options.integrity !== undefined
    ) {

        if (options !== null
            && options !== undefined
            && options.crossOrigin !== null
            && options.crossOrigin !== undefined
        ) {
            s.crossOrigin = options.crossOrigin;
        } else {
            s.crossOrigin = 'anonymous';
        }

        s.integrity = options.integrity;
    }
    s.onload = s.onreadystatechange = function () {
        if (!r && (!this.readyState || this.readyState === 'complete')) {
            r = true;
            if (options !== null
                && options !== undefined
                && options.onload !== null
                && options.onload !== undefined
            ) {
                options.onload();
            }
        }
    };

    t = document.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
}

if (!Modernizr.promises) {
    loadScript(
        "https://cdn.jsdelivr.net/npm/promise-polyfill@7/dist/polyfill.min.js",
        {
            integrity: 'sha384-cIk1l7i96ZLEqMMs99n3RnyJo/WYQumUep5bnm5DaJ34SCfYrsR1elBJfJUfONrv',
            crossOrigin: 'anonymous'
        }
    );
}

if (!Modernizr.fetch) {
    loadScript(
        "https://cdnjs.cloudflare.com/ajax/libs/fetch/2.0.3/fetch.min.js",
        {
            integrity: 'sha384-+YY1buT7tM9na0/PzJFojzO+W6HFlbYTba/CUsXtflkAYB4ZE8WC5cOcbEyo0CIb',
            crossOrigin: 'anonymous'
        }
    );
}


if (!Modernizr.urlsearchparams) {
    loadScript(
        "https://cdnjs.cloudflare.com/ajax/libs/url-search-params/1.1.0/url-search-params.js",
        {
            integrity: 'sha384-PFIMXSxSGNYqc05K2vGfJqqfnbi6BuLemZXrRoApZMexSYt1mpumUvEPLHLG8iJb',
            crossOrigin: 'anonymous'
        }
    );
}